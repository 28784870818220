


















import {Component, Vue} from 'vue-property-decorator';
import SublimeSearch from "@/components/common/SublimeSearch.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import CreateCustomRequestPayload from "@/dto/request/custom/CreateCustomRequestPayload";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import PortalDateTime from "@/components/common/PortalDateTime.vue";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";
import CustomRequestService from "@/services/request/CustomRequestService";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import PortalDate from "@/components/common/PortalDate.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

@Component({
  components: {SingleFileHolder, PortalDate, PortalDateTime, PortalTextarea, PortalInput, SublimeSearch}
})
export default class CreateCustomRequest extends Vue {

  payload = new CreateCustomRequestPayload()

  addDocument(file: FileMetaDTO): void {
    this.payload.files.push(file);
  }

  onAssigneeChanged(assignee: SublimeEmployeeDTO): void {
    this.payload.assigneeId = assignee.id;
  }

  create() {
    Application.startLoading();
    CustomRequestService.create(this.payload).then(
        ok => {
          Application.stopLoading();
          this.$emit("create");
        },
        err => {
          processError(err, this);
        }
    )
  }

}
